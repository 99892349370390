/* .callout-border{
    border-left: 5px solid;
    border-right: 1px solid #e9ecef !important;
    border-top: 1px solid #e9ecef !important;
    border-bottom: 1px solid #e9ecef !important;
} */

.callout-border {
  border: 0;
  border-left: 5px solid;
}

.App {
  align-items: stretch;
}

.upload-input-label {
  cursor: pointer;
}
