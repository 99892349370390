body {
    min-height: 100vh;
    min-height: -webkit-fill-available;
  }
  
  html {
    height: -webkit-fill-available;
  }
  
  .sidebar-container {
    display: flex;
    flex-wrap: nowrap;
    min-height: 100vh;
    overflow-x: auto;
    overflow-y: hidden;
  }